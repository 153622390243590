@use "src/style/base";

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.xxSmall {
  font-size: base.$text-x-x-small;
}

.xSmall {
  font-size: base.$text-x-small;
}

.small {
  font-size: base.$text-small;
}

.medium {
  font-size: base.$text-medium;
}

.large {
  font-size: base.$text-large;
}

.xLarge {
  font-size: base.$text-x-large;
}
