@use "../../../style/base";

.formBox {
  min-width: 120%;
  color: base.$default-text-color;
  margin-top: 2rem;
}

.headline {
  font-weight: base.$font-light;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.ibanInputField {
  margin-bottom: 0.5rem;
  border: 1px solid;
  border-radius: 10px;
  padding: 0.5rem;
  width: 23rem;
  height: 3rem;
  font-size: base.$text-large;
}

.ibanInputFieldDescription {
  width: 23rem;
  margin-bottom: 3rem;
}

.checkboxBox {
  margin-bottom: 2rem;
}

.checkboxItem {
  align-items: center;
}

.radio {
  width: 27px;
  height: 27px;
  margin-right: 1rem;
  border-radius: 100%;
  border: 1px solid base.$default-border-color;

  &:checked {
    background: black;
    box-shadow: inset 0 0 0 5px white;
  }

  &:checked::before {
    display: none;
  }
}
