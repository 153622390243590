@use "/src/style/colors";

.itemContainer {
  max-height: 2rem;
}

.toggleItem {
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: colors.$border-gray;
  border-style: solid;
  padding: 0.25rem 0.35rem;
  color: colors.$switch-toggle-active-bg;
  cursor: pointer;

  &.inactive {
    color: colors.$switch-toggle-inactive;
  }
}

.left {
  border-right-width: 0;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.right {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
