@use "../../../style/base";

.btnPrimary {
  display: flex;
  cursor: pointer;
  padding: 0.75rem 2rem;
  background-color: base.$secondary-color;
  border-radius: 0.7rem;
  align-items: center;
  justify-content: space-around;
  border: 2px solid base.$secondary-color;
  color: base.$primary-color;
  font-size: base.$btn-label-regular;
  font-weight: base.$font-semibold;

  &:hover {
    background-color: base.$primary-color;
    transform: scale(1.03);
    transition: all 0.2s ease-in-out;
    color: base.$btn-primary;
    border: 2px solid base.$btn-primary;
  }
}

@include base.media-breakpoint-down(xl) {
  .btnPrimary {
    width: 15rem;
    height: 2.5rem;
    font-size: base.$btn-label-regular-md;
  }
}
