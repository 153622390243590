@use "src/style/base";

.label {
  font-size: base.$text-small;
  align-self: center;
}

.inputContainer {
  justify-self: end;
  width: 100%;
}
