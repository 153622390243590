@use "src/style/base";

.insuranceComparisonListBoxItemNumber {
  align-self: center;
  margin-right: 1rem;
  color: base.$default-text-color;
  font-weight: base.$font-semibold;
}

.insuranceComparisonListBoxItemImageBox {
  border-radius: 1.2rem;
  border: 1px solid base.$default-border-color;
  height: 5rem;
  width: 7rem;
  background-color: base.$text-headline-color;
}

.insuranceComparisonListBoxItemImage {
  border-radius: 1.2rem;
  border: 1px solid base.$default-border-color;
  height: 5rem;
  width: 7rem;
}

.insuranceComparisonListBoxItemInsuranceName {
  color: base.$default-text-color;
  text-align: center;
  margin-top: -0.2rem;
}
