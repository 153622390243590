@use "src/style/base";

.insuranceStepBox {
  width: 50rem;
  align-items: center;
}

.insuranceBadgeBox {
  margin-left: auto;
  margin-right: auto;
}

.previousDamageBox {
  width: inherit;
}

.divider {
  flex-grow: 1;
  border-bottom: 1px solid base.$default-border-color;
  margin-bottom: 5.3rem;
  width: 3rem;
  margin-left: -1rem;
  margin-right: -1rem;
}

.button {
  width: 13rem;
  height: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 3rem;
}

.buttonBack {
  background-color: base.$btn-secondary;
}
