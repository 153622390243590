@use "../../../../../style/base";

.highlighted {
  color: base.$text-highlight-color;
}

@include base.media-breakpoint-down(md) {
  .section {
    font-size: base.$text-small;
  }

  .section h2 {
    font-size: base.$text-medium;
  }

  .sectionTitle {
    text-align: left;
    margin: 0 0 1em;
  }
}
