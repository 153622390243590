@use "../../../../../style/base";

.section {
  text-align: center;
}

.subtitle {
  font-size: base.$text-large;
  font-weight: base.$font-lighter;
  margin: 1rem;
}

.imageBox {
  max-width: 30ch;
  overflow: hidden;
  margin: 2rem 0;
  margin-inline: auto;
  width: 100%;

  .img {
    display: flex;
    width: 100%;
    height: auto;
    object-fit: fill;
    margin-inline: auto;
  }
}

.registerNowButton {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-inline: auto;
  width: 100%;
}

.buttonLink {
  display: block;
  min-width: base.$button-desired-width;
  width: 30ch;
  margin: 0 auto;
}

@include base.media-breakpoint-down(md) {
  .subtitle {
    display: none;
  }

  .section {
    text-align: left;
  }
}
