@use "src/style/base";

.contractTitle {
  font-size: base.$text-large;
  font-weight: bold;
  color: base.$text-default-color;
}

.contractSubtitle {
  color: base.$text-purple-gray;
}

.contentDivider {
  border-top: 1px solid base.$text-purple-gray;
}

.contractIcon {
  width: 3rem;
  height: 3rem;
}

.contractAccordion {
  width: 100%;

  .tableContainer {
    overflow-x: auto;

    table {
      width: 100%;
      color: base.$text-purple-gray;
    }

    thead {
      td {
        font-weight: base.$font-bold;
        color: base.$text-default-color;
        padding: 0.15rem 0;
      }
    }
  }
}
