@use "src/style/base";

.unstyledLink {
  text-decoration: none;
  color: base.$default-text-color;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
}
