@use "../../../style/base";

.formBox {
  min-width: 120%;
  color: base.$default-text-color;
  margin-top: 2rem;
}

.headline {
  font-weight: base.$font-light;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.checkboxBox {
  margin-bottom: 1rem;
  margin-left: 3rem;
}

.checkboxItem {
  align-items: center;
}

.radio {
  width: 27px;
  height: 27px;
  margin-right: 1rem;
  border-radius: 100%;
  border: 1px solid base.$default-border-color;

  &:checked {
    background: black;
    box-shadow: inset 0 0 0 5px white;
  }

  &:checked::before {
    display: none;
  }
}

.filterBoxBox {
  min-width: 15rem;
  margin-bottom: 1rem;
}

.amountInYear {
  margin-left: 2rem;
  border: 1px solid;
  border-radius: 10px;
  padding: 0.5rem;
  width: 14rem;
  height: 2rem;
}

.previousDamageBoxWrapper {
  margin-left: 3rem;
}

.previousDamageBox {
  margin-top: 0.5rem;

  &:last-of-type {
    margin-bottom: 0.5rem;
  }
}

.dateField {
  max-width: 15rem;
  margin-left: 5.5rem;
  border: 1px solid base.$default-border-color;
  border-radius: 5px;
  color: base.$default-text-color;
}
