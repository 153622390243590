@use "../../../style/base";

.usps {
  display: flex;
  flex-wrap: wrap;
  gap: 4ch;
  justify-content: center;
}

.usp {
  flex-basis: 20ch;
  flex-grow: 1;
  max-width: 30ch;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.icon {
  flex-basis: 3em;
  flex-grow: 0;
  max-height: 100%;
  height: 100%;
  max-width: 100%;
}

.title {
  font-size: base.$text-large;
  color: base.$text-highlight-color;
  font-weight: base.$font-semibold;
  margin-bottom: 0.5em;
}
