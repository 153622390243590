.react-responsive-modal-modal, .react-responsive-modal-overlay {
  animation-fill-mode: forwards !important;
}

.react-responsive-modal-container {
  width: 100%;
}

.react-responsive-modal-modal {
  max-width: 100%;
}
