@use "src/style/base";

.smallHint {
  margin-top: 0.8rem;
  font-size: base.$text-small;
  color: base.$default-text-color;

  a {
    color: base.$primary-color;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
