@use "src/style/base";

.addContractContainer {
  @include base.rounded-box;

  background: base.$background-white;
  padding: 1.5rem;

  .controlsContainer {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding-top: 2rem;

    .backButton {
      background: base.$primary-color;
      color: base.$text-white;
      border-color: base.$primary-color;
    }

    .backButton:hover {
      background: base.$background-white;
      color: base.$text-default-color;
    }
  }

  .titleContainer {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2rem;
    flex-wrap: wrap;

    .title {
      color: base.$text-highlight-color;
      font-weight: bold;
      font-size: base.$text-large;
    }
  }
}

@include base.media-breakpoint-down(md) {
  .wizardProgress {
    display: flex;
    flex: 1;
    padding-top: 1rem;
  }

  .controlsContainer {
    button {
      width: 45%;
    }
  }
}
