.radioGroup {
  display: flex;
  padding-left: 0.5rem;
  gap: 1.5rem;
}

.horizontal {
  flex-direction: row;
}

.vertical {
  flex-direction: column;
  gap: 0.5rem;
}
