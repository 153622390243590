@use "src/style/base";

.portal {
  height: 100%;
  display: grid;
  grid-template-columns: 20rem auto;
  grid-template-rows: auto;
}

.portalSideboard {
  background: base.$background-default-color;
}

.portalMain {
  background: base.$background-gray;
}

@include base.media-breakpoint-down(md) {
  .portal {
    grid-template-columns: auto;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
}

.portalHeader {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  max-width: base.$content-width;
}

.portalContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.sidebox {
  display: flex;
  width: min(15em, 20%);
}

.portalContentView {
  display: flex;
  width: 100%;
}

@include base.media-breakpoint-down(md) {
  .contentContainer {
    flex-direction: column;
  }

  .column {
    border: 0 none;
    padding: 0;
  }

  .sidebox {
    display: none;
  }
}
