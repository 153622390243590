body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


body,
:global(#root) {
  min-height: 100vh;
  font-family: "Open Sans", "Arial", sans-serif;
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Configuration */

:root {
  line-height: 1.5;

  /* Colors */
  --primary-color-rgb: 68, 114, 196;
  --secondary-color: #f2f2f2;
  --secondary-color-rgb: 242, 242, 242;
  --fourth-color: #1f97b3;
  --fourth-color-rgb: 31, 151, 179;

  --page-background: #ffffff;

  /* Colors Error & Success */
  --error-color: #ec0000;
  --error-color-rgb: 236, 0, 0;
  --success-color: #33ec00;
  --success-color-rgb: 51, 236, 0;

  /* Mobile navigation */
  --mobile-navigation-transition-delay: 0.5s;

  /* Page specific colors: Dashboard */
  --dash-card-shadow-prim: rgba(213, 216, 223, 0.5);

  /* Page specific colors: ?*/

  --input-color: #152939;
  --input-background-color: #ffffff;
  --input-border: 1px solid #c4c4c4;
  --input-border-color-focus: base.$primary-color;

  /* Default padding */
  --default-space-horizontal: 4rem;
  --default-space-horizontal-mobile: 2rem;


  /* Z - index */
  --zindex-header: 10;
  --zindex-overlay: 11;
  --zindex-popup: 2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: lighter;
}
