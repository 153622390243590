@use "../../../../../style/base";

.box {
  background: base.$background-gray;
  border-radius: base.$border-radius;
  padding: 0 1em;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-basis: 30%;
  flex-shrink: 0;
  min-width: 40ch;
  margin: 0 2rem;
}

.bulletPoints {
  list-style-type: none;
  font-size: base.$text-small;
  padding-left: 0;
}

.bulletPoints > li {
  margin-top: 1em;
  margin-bottom: 1em;
}

.boxContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.bullets {
  font-size: base.$text-small;
  list-style-type: none;
  padding-left: 0;
}

.listItem {
  display: flex;
  justify-content: flex-start;
  gap: 0.4em;
  align-items: flex-start;
}

.listIcon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-top: -0.2em;
}

.listItem.positive > .listIcon {
  color: base.$font-green;
}

.listItem.negative > .listIcon {
  color: base.$font-red;
}
