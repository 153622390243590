@use "src/style/base";

.insuranceDataBox {
  background-color: base.$usp-box-bg;
  border: 1px solid base.$default-border-color;
  border-radius: 9px;
  width: 46rem;
  height: 14rem;
  padding-right: 1rem;
  padding-left: 2rem;
  padding-top: 2rem;
}

.insuranceInfoBox {
  margin-left: 1rem;
}

.detailsLink {
  margin-top: 0.5rem;
  margin-left: 4rem;
  color: base.$default-text-color;
}

.insuranceImage {
  margin-top: 1rem;
}

.featureListBox {
  margin-right: 1rem;
  margin-top: 3rem;
  margin-left: auto;
  justify-self: flex-end;
}

.verticalRule {
  border-left: 0.5px solid base.$default-border-color;
  height: 80%;
  margin-bottom: auto;
  margin-right: 0.5rem;
}

.priceInformation {
  height: auto;
}

.insurancePriceBox {
  gap: 0.5rem;
  max-width: 10rem;
}

.callToActionBtn {
  margin-top: 3rem;
}

.ratingBox {
  gap: 3rem;
  margin-right: auto;
  margin-left: 11rem;
}

.compareBox {
  margin-top: 0.2rem;
}

.ratingCompareNow {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.2rem;
  margin-top: -0.3rem;

  &:checked::before {
    width: 0.5rem;
    height: 1rem;
    margin-left: 0.5rem;
    margin-top: 0.2rem;
  }
}

.ratingCompareNowLabel {
  font-size: base.$text-small;
  font-weight: base.$font-semibold;
}
