.column {
  flex-direction: column;
}

.flex {
  display: flex;
}

.inline {
  display: inline;
}

.inlineFlex {
  display: inline-flex;
}
