@use "../../../style/base";

.section {
  text-align: center;
}

.checkButton {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-inline: auto;
  width: 100%;
}

.buttonLink {
  display: block;
  min-width: base.$button-desired-width;
  width: 40ch;
  margin: 0 auto;
}
