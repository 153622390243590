@use "src/style/base";

.paddinglessHeader {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.imageHeadline {
  position: relative;
}

.insuranceImageWrapper {
  overflow: hidden;
  clip-path: polygon(0% 0%, 100% 0%, 100% 84%, 45% 84%, 13% 35%, 0% 35%);
  width: 100%;
}

.insuranceImage {
  max-width: 100%;
  max-height: 100%;
}

.insuranceTitle {
  bottom: 15%;
  position: absolute;
  color: base.$text-white;
  height: 5em;
  width: 35%;
  align-items: flex-end;
  display: flex;
  font-size: min(2em, 3.2vw);
  padding-left: base.$page-horizontal-padding;
}

.prose {
  margin-top: -2em;
  padding: 0 base.$page-horizontal-padding 1em base.$page-horizontal-padding;
}

@include base.media-breakpoint-down(md) {
  .insuranceImageWrapper {
    clip-path: polygon(0% 0%, 100% 0%, 100% 84%, 0% 84%);
    padding: 0;
  }

  .imageHeadline {
    position: static;
    padding: 0;
  }

  .insuranceTitle {
    position: static;
    align-items: flex-start;
    width: 100%;
    height: auto;
    font-size: base.$text-large;
    font-weight: base.$font-lightest;
  }

  .prose {
    margin-top: 0;
    font-size: base.$text-small;
  }
}
