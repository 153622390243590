@use "src/style/base";

.navigationItemButton {
  &,
  &:visited {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: base.$primary-color;
    font-size: base.$navigation-item;
  }

  &:hover,
  &.active {
    font-weight: bold;
    color: base.$primary-color;
  }
}
