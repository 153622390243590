@use "src/style/base";

.toolTip {
  visibility: hidden;
  position: absolute;
  margin-top: -4rem;
  border: 1px solid base.$default-border-color;
  border-radius: 10px;
  background-color: base.$tooltip-background-default;
  opacity: 1;
  width: 225px;
  min-height: 50px;
  color: base.$default-text-color;
  padding: 0.5rem;
}

.hasToolTip {
  cursor: pointer;
  color: base.$default-text-color;

  &.hasToolTip:hover .toolTip {
    visibility: visible;
    z-index: 50;
  }
}

.toolTipIcon {
  color: base.$tooltip-text-default;
  height: 22px;
  width: 22px;
}
