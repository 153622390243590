.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 1s;
  opacity: 0;
  background: radial-gradient(circle, rgb(var(--fourth-color-rgb) 0.4) 0%, rgb(var(--page-background) 0.4) 100%);
  z-index: -1;

  &.visible {
    opacity: 1;
    z-index: 1;
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    // Move spinner to the center
    // Unfortunately the origin of the spinner is not in the center, so these must be the half of its size
    margin-left: -100px;
    margin-top: -100px;
  }
}
