@use "../../style/base";

.section {
  max-width: base.$content-width;
  width: 100%;
  margin-inline: auto;
  padding: 2em base.$page-horizontal-padding 5em;
}

.fullHorizontalWrapper {
  width: 100%;
}

.greyBackground {
  background-color: base.$background-gray;
}

.blueBackground {
  background: base.$primary-color;
  color: base.$text-white;
}
