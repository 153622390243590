@use "src/style/base";
@import "src/style/breakpoints";

.nameContainer {
  font-size: base.$text-large;
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding: 1rem 0;
}
