@use "src/style/base";

.filterBox {
  flex-wrap: wrap;
}

.item {
  position: relative;
  display: inline-block;
  width: 137px;
  height: 45px;
  border: 1px solid base.$default-border-color;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex: 0 0 40%;

  &:only-child {
    border-radius: 5px;
  }

  &:first-child {
    border-top-left-radius: 5px;
  }

  &:last-child {
    border-bottom-right-radius: 5px;
  }

  &:nth-child(2) {
    border-top-right-radius: 5px;
  }

  &:nth-child(2n) {
    border-left: none;
  }

  &:nth-child(1n + 3) {
    border-top: none;
  }

  &:last-child:nth-child(odd) {
    border-bottom-left-radius: 5px;
  }

  &:nth-last-child(2):nth-child(odd) {
    border-bottom-left-radius: 5px;
  }

  &:nth-last-child(2):nth-last-child(2):nth-child(even) {
    border-bottom-right-radius: 5px;
  }

  p {
    text-align: center;
  }
}

.item input {
  opacity: 0;
  width: 0;
  height: 0;
}

.spanWrapper {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

input:checked + .spanWrapper {
  background-color: base.$filter-box-active;
  z-index: -1;
}

.textWrapper {
  position: relative;
  bottom: 1rem;
}
