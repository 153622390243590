@use "../../../../../style/base";

.section {
  text-align: center;
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  background: base.$background-gray-darker;
  width: fit-content;
  padding: 0.3em;
  margin: 1.5em auto;
  border-radius: 20px;
  max-width: 95%;
}

.navigationItem {
  flex-grow: 0;
  flex-shrink: 1;
  background: transparent;
  border: 0;
  padding: 0.2em min(2em, 4vw);
  border-radius: base.$border-radius;
  cursor: pointer;
}

.navigationItem.active {
  background: white;
}

.highlighted {
  color: base.$text-highlight-color;
}

.itemWrapper {
  display: flex;
  width: base.$content-width;
  justify-content: center;
  align-items: flex-start;
  max-width: 100%;
  gap: 5rem;
  margin: 2rem 0 0;
}

.itemText {
  text-align: left;
  flex-basis: 50%;
  max-width: 40ch;
  padding: 0;
  margin: 0;
}

.itemImage {
  flex-basis: 50%;
  max-width: 50ch;
}

.itemImage > img {
  max-width: 100%;
  max-height: 100%;
}

@include base.media-breakpoint-down(md) {
  .section {
    text-align: left;
  }

  .navigation {
    flex-direction: row;
    margin-bottom: 0;
  }

  .itemImage {
    display: none;
  }

  .itemWrapper {
    align-self: flex-start;
    margin: 2rem 0;
  }

  .itemText {
    flex-basis: 100%;
  }
}
