@use "src/style/base";

.title {
  margin-bottom: 0.75rem;
  font-size: base.$h1-big-xl;
  color: base.$default-text-color;
  font-weight: base.$font-semibold;
}

.subtitle {
  margin-top: 3.5rem;
  margin-left: 1rem;
  color: base.$default-text-color;
  width: auto;
}

.optionCardBox {
  gap: 0.5rem;
}

.filterBox {
  margin-top: 1rem;
}

.toggleSwitchBox {
  gap: 0.5rem;
  margin-left: 0.5rem;
}

.filterOptionBox {
  width: 277px;
  margin-top: 2rem;
}

.sortFilterBox {
  margin-top: 3rem;
  margin-bottom: 2rem;
  margin-left: 5.5rem;
  gap: 0.5rem;
}

.sortFilterBoxTitle {
  padding-top: 0.3rem;
}

.listFilter {
  min-width: 9rem;
  margin-bottom: 1rem;
}

.insuranceDataBoxComponent {
  margin-left: 5rem;
  margin-bottom: 1rem;
}

.insuranceComparisonComponent {
  justify-content: flex-end;
  margin-top: 5rem;
}
