@use "src/style/base";

.documentEntry {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 5px 0;

  .documentEntryRow {
    display: flex;
    width: 75%;
  }

  .fileName {
    width: 100%;
    margin-right: 0.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: base.$default-text-color;
  }

  .iconButton {
    padding: 3px 5px;
    margin: 0 4px;
    font-size: base.$btn-label-regular;
    background: base.$btn-secondary;
    border-radius: 3px;
    color: white;
    cursor: pointer;

    &:hover {
      background: base.$btn-secondary-hover;
    }
  }
}
