@use "src/style/base";

.title,
.subTitle {
  text-align: center;
}

.subTitle {
  font-size: base.$text-large;
  font-weight: base.$font-lighter;
}

.highlighted {
  color: base.$secondary-color;
}

.teamList {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  border-radius: base.$border-radius;
  margin-bottom: 1rem;
  margin-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.teamCard {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  font-size: base.$text-medium;
  font-weight: normal;
  padding: 1rem;

  .person {
    font-size: base.$text-large;
    font-weight: base.$font-lighter;
    margin-top: 1em;
  }

  .img {
    display: flex;
    width: 100%;
    height: auto;
    object-fit: fill;
    font-weight: normal;
  }

  .position {
    font-weight: normal;
  }

  .text {
    font-style: italic;
    text-align: left;
  }

  .email {
    opacity: 0.8;
  }
}

.imgContainer {
  min-width: 128px;
  min-height: 128px;
  max-width: 128px;
  max-height: 128px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}

@include base.media-breakpoint-down(md) {
  .subTitle {
    font-size: base.$text-medium;
    font-weight: base.$font-normal;
  }

  .teamCard {
    width: 100%;
    font-size: base.$text-x-small;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;

    .person {
      font-size: base.$text-medium;
      font-weight: base.$font-lighter;
    }
  }

  .imgContainer {
    display: flex;
    min-width: 108px;
    min-height: 108px;
    max-width: 108px;
    max-height: 108px;
  }

  .teamCard .text {
    text-align: center;
  }
}
