@use "src/style/base";

.desktopNavigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  position: sticky;
  top: 0;
  background-color: base.$primary-color;
  box-shadow: 0 2px 5px var(--dash-card-shadow-prim);
  padding: 1rem var(--default-space-horizontal) 1rem var(--default-space-horizontal);
  margin: 0;
  z-index: var(--zindex-header);
}

.desktopNavigationBox {
  width: base.$content-width;
  justify-content: space-between;
  align-items: center;
}

.desktopNavigationButton {
  margin: 0;
}

.logo {
  margin-right: 2rem;
}

.navigation {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  height: auto;
  transition: 200ms ease-in-out;
  gap: 3rem;
  font-size: base.$navigation-item;

  &.hidden {
    display: none;
  }
}

.navigationIcon {
  font-size: 1.5em;
  padding-right: 3px;
}

.menuButton {
  display: none;
  text-align: center;
}

@media only screen and (max-width: 724px) {
  .desktopNavigation {
    flex-direction: column;
    justify-content: center;
  }
}

@media only screen and (max-width: 500px) {
  // Disable on mobile because it's looking ugly and
  .menuButton,
  .desktopNavigation .navigationButtons {
    display: none;
  }

  .desktopNavigation {
    justify-content: center;
  }

  .logo {
    width: 100%;
    text-align: center;
    padding: 1rem 0;
  }

  .desktopNavigation .navigation {
    width: 100%;
    flex-direction: column;
    margin: 0 auto;
    border-radius: 4px;
  }

  .navigation li {
    margin: auto;
  }
}
