@use "../../style/base";

.introductionText {
  text-align: center;
  margin-top: 2rem;
  color: base.$default-text-color;
}

.content {
  padding: 3rem;
  margin-top: 3rem;
  text-align: center;
  border: 1px solid base.$border-light-gray;
  border-radius: 0.625rem;
  background-color: base.$background-gray;
}

.headline {
  color: base.$default-text-color;
  margin-bottom: 1rem;
}

.forgotPasswordCodeInputContainer {
  margin-top: 1rem;
  border: 1px solid base.$border-gray;
  border-radius: 12px;
  padding: 1rem 5rem;
}

.error {
  color: var(--error-color);
}

.resendCodeHint {
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid var(--success-color);
  background-color: rgb(var(--success-color-rgb) 0.3);
  color: base.$default-text-color;
}

@include base.media-breakpoint-down(md) {
  .forgotPasswordCodeInputContainer {
    padding: 1rem 2rem;
  }
}
