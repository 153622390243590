@use "src/style/base";

.totalScoreContainer {
  padding: 1.5rem;
}

.barTitle {
  display: block;
  color: base.$default-text-color;
  padding: 10px 0 4px;
  font-size: 0.8rem;
}

.progressBar1Color {
  background: base.$background-highlight-color;
}

.progressBar2Color {
  background: base.$background-purple-gray;
}

.progressBar3Color {
  background: base.$primary-color;
}

.dashboardStats {
  width: min(100%, 20rem);
}

.dashboardStatsContainer {
  display: flex;
  justify-content: center;
}
