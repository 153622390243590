.warning {
  position: fixed;
  left: 0.5em;
  bottom: 0.5em;
  font-size: 2em;
  color: red;
  font-weight: bold;
  -webkit-text-stroke: 1px black;
  pointer-events: none;
}
