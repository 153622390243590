@use "src/style/base";

.form {
  border: 1px solid base.$border-white;
  padding: 1.5em 1em;
  width: min(80ch, 100%);
  display: flex;
  flex-wrap: wrap;
}

.actions {
  flex-basis: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}

.firstInputBlock, .secondInputBlock {
  flex-basis: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;
  padding: 0 0.5rem

}

.startCompareButton {
  margin-top: 3em;
}

.booleanRadioGroup {
  display: flex;
  flex-wrap: wrap;
}

.booleanRadioGroupLabel {
  flex-basis: 100%;
}

.downloadLink {
  text-decoration: none;
  font-weight: base.$font-bold;
  color: white;
  font-size: base.$text-small;
  padding-top: 1em;
  margin-bottom: 1em;
  display: block;
}
