@use "src/style/base";

.footer {
  background: base.$primary-color;
  color: base.$text-white;
  padding: 1em 0;
}

.footer > section {
  max-width: base.$content-width;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2vw;
}

.footline {
  flex-basis: 100%;
  text-align: center;
  font-size: base.$text-x-small;
}

.linkLists {
  display: flex;
  justify-content: space-around;
  flex-grow: 2;
}

.linkList > h3 {
  color: base.$secondary-color;
  margin: 1em 0 0.25em;
}

.linkList > p {
  margin: 1em 0 0;
}

.linkList a {
  display: block;
  color: base.$text-white;
  text-decoration: none;
}

@include base.media-breakpoint-down(md) {
  .linkLists {
    flex-direction: column;
  }
}
