@use "src/style/base";

.demandCalculator {
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  gap: 2rem;
}

.inputPane {
  flex: 0 1 350px;
  flex-flow: wrap;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.outputPane {
  flex: 1;
}

.resultContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

h1 {
  .emphasized {
    color: base.$text-highlight-color;
  }
}

@include base.media-breakpoint-down(md) {
  .demandCalculator {
    flex-direction: column;
  }
}
