@use "src/style/base";

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
  gap: 1rem;
}

.hidden {
  opacity: 0;
  height: 1px;
}
