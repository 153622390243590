@use "src/style/base";

.periodText {
  margin: auto 1.5rem 0 1rem;
  color: base.$default-text-color;
}

.highlightValue {
  color: base.$text-highlight-color;
  margin-right: 0;
  margin-left: auto;
}

.regularValue {
  color: base.$default-text-color;
  margin-right: 0;
  margin-left: auto;
}
