@use "src/style/base";

.contractBaseSection {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;

  .produktTypOptions {
    display: flex;
    gap: 1rem;
  }

  .produktTypOptionInput {
    width: 15px;
    height: 15px;
  }

  .produktTypOptionLabel {
    font-size: base.$text-medium;
    margin-left: 0.5rem;
  }

  .contractTypeSelection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
  }
}

@include base.media-breakpoint-down(md) {
  .contractBaseSection {
    .produktTypOptions {
      flex-direction: column;
    }

    .contractTypeSelection {
      grid-template-columns: 1fr;
      grid-gap: 1.5rem;
    }
  }
}
