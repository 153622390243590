@use "src/style/base";

.sectionTitle {
  text-align: center;
}

.highlighted {
  color: base.$secondary-color;
}

.situationBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1em;
  gap: 2em;
}

.situations {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.5em;
}

.situationIconImage {
  display: block;
  max-width: 2em;
  max-height: 2em;
}

@include base.media-breakpoint-down(md) {
  .sectionTitle {
    text-align: left;
  }
}
