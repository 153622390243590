@use "src/style/base";

.sectionTitle {
  text-align: center;
  margin-bottom: 2em;
}

.covomoIframe {
  border: 0;
  min-height: 3000px;
  width: 100%;
  overflow: hidden !important;
}

@include base.media-breakpoint-down(md) {
  .sectionTitle {
    text-align: left;
  }
}
