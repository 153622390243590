@use "../../../../../style/base";

.section {
  display: flex;
  flex-flow: row wrap;
}

.leftHeaderBox,
.rightHeaderBox {
  display: flex;
  flex-flow: row wrap;
  gap: 2em;
}

.highlighted {
  color: base.$secondary-color;
}

.contentLeft {
  display: flex;
  flex-flow: column wrap;
  flex-basis: 45%;
  flex-grow: 1;
  justify-content: space-between;
  margin: 1em auto;
  padding-left: 5em;
  padding-right: 5em;
  text-align: left;
}

.contentRight {
  display: flex;
  flex-flow: column wrap;
  flex-basis: 45%;
  flex-grow: 1;
  margin: 1em auto;
  padding-left: 5em;
  padding-right: 5em;
  align-items: flex-end;
  justify-content: space-between;
  text-align: right;
}

.leftIcon,
.rightIcon {
  height: 4em;
}

.leftIcon {
  align-self: center;
}

.rightIcon {
  align-self: center;
}

.actionButton {
  margin-top: 2em;
  width: 24ch;
}

.buttonLink {
  display: block;
}

@include base.media-breakpoint-down(md) {
  .sectionWrapper {
    margin: 0;
    padding-bottom: 2em;
  }

  .section {
    flex-flow: column wrap;
    margin: 0;
    gap: 3rem;
  }

  .contentLeft,
  .contentRight {
    flex-basis: 100%;
    padding: 0;
    text-align: left;
    align-items: center;
  }

  .leftIcon,
  .rightIcon {
    align-self: center;
    height: 3em;
  }

  .rightHeaderBox {
    flex-flow: row-reverse wrap;
    width: 100%;
    justify-content: flex-end;
  }

  .leftHeaderBox {
    width: 100%;
  }
}
