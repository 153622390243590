@use "src/style/base";

.ratingTitle {
  margin-right: 0.5rem;
  font-weight: base.$font-semibold;
}

.ratingValues {
  font-weight: base.$font-light;
  color: base.$default-text-color;
}
