@use "src/style/base";
@import "src/style/breakpoints";

.customerName,
.customerSalutation {
  padding-left: 6px;
}

.verticalSpace {
  padding: 0.2rem 0;
}

.grid {
  width: 100%;

  .profile {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 2rem;
  }

  .profileCard {
    height: 100%;
  }
}

@include base.media-breakpoint-down(md) {
  .grid {
    .profile {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      grid-gap: 1.5rem;
    }
  }
}
