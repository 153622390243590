@use "../../../style/base";

.content {
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 2ch;
}

.comparisonImage {
  max-width: 100%;
  max-height: 100%;
  display: block;
  width: min(50ch, 95%);
  margin-inline: auto;
}

.textSide,
.imageSide {
  flex-basis: 50ch;
  flex-grow: 1;
}

.imageSide {
  align-self: end;
}

.checklistItem {
  display: flex;
  gap: 1ch;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
}

.checkmark {
  color: base.$font-green;
  font-size: 1.5em;
}

.compareNowLink {
  margin-inline: auto;
  display: block;
  width: 30ch;
  max-width: 100%;
  margin-top: 2em;
}

.compareNowButton {
  width: 100%;
}
