@use "src/style/base";

.cardContainer {
  @include base.rounded-box;

  background: base.$background-white;
  padding: 0;
  flex-grow: 1;
}

.cardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 1rem 0;

  .headline {
    justify-content: center;
    align-self: center;
    color: base.$text-default-color;
    font-size: base.$text-medium;
    font-weight: lighter;
  }
}

.cardContent {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 1rem 1rem;
  padding-top: 0.5rem;
  border-top: 1px solid base.$text-purple-gray;
  font-size: base.$text-x-small;
}

.cardEntry {
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0;

  .label {
    font-weight: base.$font-bold;
    padding-right: 0.25rem;
    color: base.$text-default-color;
  }

  .value {
    text-align: right;
    color: base.$text-purple-gray;
  }

  span {
    font-size: base.$text-small;
  }
}
