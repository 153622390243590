@use "src/style/base";

.messageForm {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .fields {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .errorMessage {
    display: flex;
    justify-content: center;
    color: base.$font-red;
    font-size: base.$text-small;
  }

  .controls {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    button {
      width: 100%;
      flex-grow: 1;
    }
  }
}

.modalDialog {
  width: min(40rem, calc(100% - 2.4rem));
}
