@import "font-sizes";
@import "colors";
@import "breakpoints";
@import "form-inputs";
@import "input-mixins";
@import "rounded-box";
@import "modal-dialog";

$content-width: 1326px;
$page-width: 1366px;
$page-horizontal-padding: 1rem;
$border-radius: 1rem;
$button-desired-width: 15rem;
