/**
 * Media breakpoints
 * Each value defines the lower bound of its category. Use it with min-width: var(...)
 *
 * Based on:
 * - https://www.mediaevent.de/css/breakpoints.html
 * - https://getbootstrap.com/docs/5.0/layout/breakpoints/
 * - https://www.freecodecamp.org/news/css-media-queries-breakpoints-media-types-standard-resolutions-and-more/#common-breakpoints-is-there-a-standard-resolution
 * - https://gs.statcounter.com/screen-resolution-stats#monthly-202008-202108-bar
 */
$app-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 992px,
  lg: 1200px,
  xl: 1400px,
) !default;

/**
 * Adapted from https://github.com/twbs/bootstrap/blob/main/scss/mixins/_breakpoints.scss
 */

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $app-breakpoints) {
  $min: map-get($breakpoints, $name);

  @return if($min != 0, $min, null);
}

// Maximum breakpoint width.
// The maximum value is reduced by 0.02px to work around the limitations of
// `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//    >> breakpoint-max(md, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints:$app-breakpoints) {
  $max: map-get($breakpoints, $name);

  @return if($max and $max > 0, $max - 0.02, null);
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $app-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $app-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);

  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}
