.investments {
  &.col1 {
    width: 18%;
  }

  &.col2 {
    width: 170px;
  }

  &.col3 {
    width: 13%;
  }

  &.col4 {
    width: 15%;
    text-align: right;
  }

  &.col5 {
    width: 16%;
    text-align: right;
  }

  &.col6 {
    width: 15%;
    text-align: right;
  }
}

.insurances {
  &.col1 {
    width: 150px;
  }

  &.col2 {
    width: 22%;
  }

  &.col3 {
    width: 165px;
  }

  &.col4 {
    width: 15%;
    text-align: right;
  }

  &.col5 {
    width: 18%;
    text-align: right;
  }
}

.winLoss,
.targetValue {
  padding-bottom: 4px;
}
