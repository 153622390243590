@use "src/style/base";

.portalSideboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
}

.kundeName {
  text-align: center;
  color: base.$text-default-color;
  margin: 0;
}

.lists {
  padding-top: 5rem;
}

.versicherungenList {
  padding-bottom: 4rem;
}

@include base.media-breakpoint-down(md) {
  .lists {
    display: none;
  }
}
