.toast {
  padding: 0.8rem 4rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;

  &.success {
    color: black;
    border-color: var(--success-color);
    background-color: rgb(var(--success-color-rgb) 0.3);
  }

  &.error {
    color: black;
    border-color: var(--error-color);
    background-color: rgb(var(--error-color-rgb) 0.3);
  }
}
