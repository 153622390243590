@use "src/style/base";

.loginBox {
  margin-top: 5rem;
  padding: 1rem;
  border: 1px solid base.$border-light-gray;
  border-radius: 0.625rem;
  background-color: base.$background-gray;
  width: min(90vw, 25rem);
}

.title {
  margin-bottom: 0.75rem;
  font-size: base.$text-x-large;
  color: base.$default-text-color;
  font-weight: base.$font-semibold;
}

.introductionText {
  text-align: center;
  margin: 1rem 0;
  color: base.$default-text-color;
}

.submitButton {
  margin-top: 1.5rem;
  width: 100%;
}
