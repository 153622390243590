@use "src/style/base";

.productEntry {
  padding: 1rem;
  font-size: base.$text-small;
  border: 1px solid base.$border-default-color;
  border-radius: base.$border-radius;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  margin: 0;
  padding: 0 0 0.5rem;
  font-size: base.$text-medium;
  color: base.$text-default-color;
  font-weight: base.$font-bold;
}

.ownershipStatusContainer {
  position: relative;
  border: 1px solid;
  background: base.$font-green;
  color: white;
  font-weight: base.$font-bold;
  border-radius: 100%;
  width: 1.5rem;
  height: 1.5rem;
  cursor: default;
}

.ownershipStatus {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image {
  width: 100%;
}

.description {
  padding: 0.5rem 0;
}

.insuranceSum {
  padding: 1rem 0 0;
}
