@use "src/style/base";

.loginBox {
  margin-top: 5rem;
  padding: 1rem;
  border: 1px solid base.$border-light-gray;
  border-radius: 0.625rem;
  background-color: base.$background-gray;
  width: min(90vw, 25rem);
}

.loginFormContainer {
  gap: 1rem;
}

.title {
  margin-bottom: 0.75rem;
  font-size: base.$text-x-large;
  color: base.$default-text-color;
  font-weight: base.$font-semibold;
}

.submitButton {
  margin-top: 1.5rem;
  width: 100%;
}

.loginHint {
  &:first-of-type {
    margin-top: 1rem;
  }

  color: base.$default-text-color;

  a {
    color: base.$linktext-default;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.formError {
  color: base.$font-red;
}
