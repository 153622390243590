@use "src/style/base";

.heading {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 0 1.5rem;
}

.headlineContainer {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
}

.headerText {
  color: base.$text-default-color;
  font-weight: bold;
  margin: 0;
}

.toggleContainer {
  display: flex;
  justify-content: center;
}

.iconContainer {
  @include base.rounded-box;

  padding: 0 0.4rem;
  background: white;
  border-radius: 50%;
}

.icon {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: base.$text-large;
  color: base.$text-default-color;
  white-space: nowrap;
  padding: 0;
  margin: 0;
}

@include base.media-breakpoint-down(md) {
  .headline {
    font-size: base.$text-large;
  }

  .addInsuranceText {
    display: none;
  }

  .toggleContainer {
    width: 100%;
    padding-top: 0.3rem;
  }
}
