@use "../../../../../style/base";

.smallTitle {
  font-size: base.$text-small;
}

.separator {
  width: 100%;
  margin: 0;
}

.bullets {
  font-size: base.$text-small;
  list-style-type: none;
  padding-left: 0;
}

.listItem {
  display: flex;
  justify-content: flex-start;
  gap: 0.25em;
  align-items: flex-start;
}

.listIcon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-top: -0.2em;
}

.listItem.positive > .listIcon {
  color: base.$font-green;
}

.listItem.negative > .listIcon {
  color: base.$font-red;
}

.upperWrapper {
  /* Not nice, but necessary to keep comparison-sections equal height */
  flex-basis: 18em;
}
