@use "../../../style/base";

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: base.$switch-toggle-inactive-bg;
  transition: 0.4s;
  border: 1px solid base.$switch-toggle-border;

  &::before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 3px;
    background-color: base.$switch-toggle-inactive;
    transition: 0.4s;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 39px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: base.$switch-toggle-active-bg;

      &::before {
        background-color: base.$switch-toggle-active;
        transform: translateX(13px);
      }
    }
  }
}

.slider.round {
  border-radius: 34px;

  &::before {
    border-radius: 50%;
  }
}

.labelText {
  align-items: center;
  margin-left: 0.5rem;
}
