@use "../../../style/base";

$header-image-top-offset: -80px;

.paddinglessHeader {
  padding: 0;
  max-width: 100%;
  width: 100%;
}

.insuranceImage {
  width: 100%;
  min-height: calc(-1 * #{$header-image-top-offset} + 100%);
  position: absolute;
  z-index: -1;
  top: $header-image-top-offset;
  object-fit: cover;
}

.imageHeadline {
  height: auto;
  overflow: hidden;
  position: relative;
  color: base.$text-white;
  font-weight: base.$font-semibold;
}

.insuranceTitle {
  font-weight: base.$font-bold;
  text-shadow: 0 0 4px black;
  font-size: 3em;
}

.text {
  padding-left: 20%;
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.item {
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  gap: 1ch;
}

.checkmark {
  font-size: 2em;
}
