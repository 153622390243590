@use "src/style/base";

.dashboardListContainer {
  @include base.rounded-box;

  background: base.$background-white;
  border: 1px solid base.$border-light-gray;
  box-shadow: none;
  padding: 0.5rem 1rem 1rem;
  height: 9.5rem;

  .header {
    color: base.$text-default-color;
    text-align: center;
  }

  .dashboardListEntry {
    justify-content: space-between;
    color: base.$default-text-color;
    line-height: 1.2rem;
  }
}

@include base.media-breakpoint-down(md) {
  .dashboardListContainer {
    display: none;
  }
}
