@use "src/style/base";

.callToActionBox {
  display: flex;
  gap: 1em;
  padding: 4em 0;
  flex-wrap: wrap;
  align-items: flex-start;
}

.actionButton {
  min-width: base.$button-desired-width;
  background-color: base.$primary-color;
  border: 2px solid base.$secondary-color;
  color: base.$secondary-color;
}

.actionButtonWrapper {
  flex-basis: 30ch;
  margin-right: 3em;
}

@include base.media-breakpoint-down(md) {
  .actionButtonWrapper {
    margin: 0;
  }

  .callToActionBox {
    padding: 2em 0;
    gap: 1.5em;
    justify-content: center;
  }
}
