@use "../../../style/base";

.inputContainer {
  display: flex;
  align-items: center;
}

.inputLabel {
  margin-bottom: 0;
}

input[type="radio"] {
  accent-color: base.$primary-color;
}
