@use "src/style/base";

td {
  padding: 0.3rem 0;
  vertical-align: top;
}

.boxArchive {
  text-align: right;
  width: 2rem;
  min-width: 45px;
  cursor: pointer;
  font-size: base.$text-medium;
}

.fixedWidth {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fullWidth {
  width: 100%;
}

.textPositiv {
  color: base.$font-green;
}

.textNegativ {
  color: base.$font-red;
}

.yield {
  text-align: right;
}
