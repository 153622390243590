@use "../../style/base";

.title {
  font-size: base.$text-title;
  font-weight: base.$font-lighter;
  color: base.$default-text-color;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 47rem;
  color: base.$default-text-color;
  padding: 0;
  gap: 0;

  h2 {
    text-align: left;
    font-weight: base.$font-bold;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  h3 {
    font-weight: base.$font-bold;
  }

  h4 {
    font-weight: base.$font-bold;
    margin-top: 0.25em;
    margin-bottom: 0;
  }

  ol {
    margin: 0;
    padding-top: 0;
    padding-left: base.$page-horizontal-padding;
    font-size: base.$text-medium;
    font-weight: base.$font-lighter;

    li {
      padding: 1rem 0 0 0.5rem;
    }

    ul {
      margin: 0;
      padding-top: 0;
      padding-left: base.$page-horizontal-padding;

      li {
        padding: 0.2rem 0 0 0.5rem;
        list-style-type: disc;
      }
    }
  }
}
