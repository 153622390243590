@use "../../../style/base";

.section {
  width: base.$content-width;
  max-width: 100%;
  margin-inline: auto;
  padding: max(3em, 3vw) base.$page-horizontal-padding;
}

.sectionTitle {
  text-align: center;
}

.highlighted {
  color: base.$secondary-color;
}

.boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: space-between;
  align-items: stretch;
}

.boxes > * {
  flex-grow: 0;
  flex-shrink: 1;
  margin-inline: auto;
}

.singular > * {
  flex-grow: 1;
  max-width: 100%;
}

@include base.media-breakpoint-down(md) {
  .sectionTitle {
    text-align: left;
  }

  .boxes {
    justify-content: center;
  }
}
