@use "src/style/base";

.textSpan {
  width: 100px;
  text-align: center;
  color: base.$default-text-color;
}

.circle {
  width: 27px;
  height: 27px;
  margin: 1rem auto;
  border-radius: 100%;
  border: 1px solid base.$default-border-color;

  &.done {
    background: black;
    box-shadow: inset 0 0 0 5px white;
  }
}
