@use "../../../../style/base";

.content {
  display: flex;
  flex-flow: row wrap;
  max-width: 95%;
  margin-inline: auto;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;
}

.title {
  text-align: center;
}

.highlighted {
  color: base.$secondary-color;
}

.listItem {
  flex: 1;
  flex-direction: column;
  justify-content: center;
  min-width: 20ch;
  text-align: center;
  align-items: center;
  background: base.$background-gray;
  border-radius: base.$border-radius;
  padding: 1em;
}

.icon img {
  height: 70px;
  margin: 1rem;
}

.featureTitle {
  margin: 0;
  text-align: center;
  font-size: base.$text-x-large;
  font-weight: normal;
}

@include base.media-breakpoint-down(md) {
  .featureTitle {
    font-size: base.$text-large;
  }

  .icon img {
    height: 40px;
  }

  .uspItems {
    display: flex;
    flex-direction: column;
  }
}
