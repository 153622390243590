@use "../../../style/base";

.section h2 {
  font-size: base.$text-large;
}

.sectionTitle {
  color: base.$text-white;
  text-align: center;
  margin: 0 0 2em;
}

.highlighted {
  color: base.$secondary-color;
}

@include base.media-breakpoint-down(md) {
  .section h2 {
    font-size: base.$text-medium;
  }

  .section {
    font-size: base.$text-small;
  }

  .sectionTitle {
    text-align: left;
    margin: 0 0 1em;
  }
}
