@use "src/style/base";

.infoBox {
  margin-left: 2rem;
  margin-bottom: 2rem;
  color: base.$default-text-color;
}

.infoRow {
  gap: 0.25rem;
}

.button {
  margin-left: 2rem;
  background: none;
  border: none;
  cursor: pointer;
  font-size: base.$text-small;
  color: base.$default-text-color-darker;
}
