@use "../../../style/base";

.sectionWrapper {
  background: base.$background-gray;
  padding-top: 3em;
  padding-bottom: 3em;
  margin-bottom: 4em;
  margin-top: 2em;
}

.section {
  max-width: base.$content-width;
  margin-inline: auto;
}

.title {
  text-align: center;
  margin: 1em;
}

.companyLogo {
  max-width: 90%;
  max-height: 100%;
  filter: grayscale(100%);
  opacity: 0.7;
  transition: filter 0.25s, opacity 0.25s;
  height: 3em;
  margin-top: 3em;
  margin-bottom: 3em;
}

.companyLogo:hover {
  filter: grayscale(0);
  opacity: 1;
}
