@use "../../../style/base";

.title {
  text-align: center;
}

.insurances {
  display: flex;
  flex-wrap: wrap;
  gap: 4ch;
}

.insurance {
  padding: 1ch;
  color: base.$lighter-text-color;
}

.insuranceImage {
  max-width: 100%;
  height: 250px;
  width: 100%;
  object-fit: cover;
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-inline: auto;
}

.insuranceTitle {
  text-align: center;
  margin-top: 0;
}

.checklistItem {
  display: flex;
  align-items: center;
  gap: 1ch;
}

.checkmark {
  color: base.$font-green;
  flex-basis: 2ch;
  flex-shrink: 0;
}

.insuranceWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 20ch;
  flex-grow: 1;
  flex-shrink: 0;
  border: 1px solid base.$border-light-gray;
  border-radius: base.$border-radius;
  padding: 0.5em;
  padding-bottom: 1em;
}

.compareNowLink {
  display: block;
  width: auto;
  max-width: 100%;
  margin-inline: auto;
  margin-top: 1em;
}

.compareNowButton {
  width: 100%;
}
