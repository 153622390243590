@use "src/style/base";

.section {
  display: flex;
  gap: 3ch;
  flex-wrap: wrap;
}

.logo {
  max-width: 100%;
  max-height: 100%;
  height: 4em;
  margin-inline: auto;
  display: block;
}

.quote {
  flex-basis: 30ch;
  flex-grow: 1;
}

.text::before {
  content: "\"";
  font-size: 3em;
  line-height: 1em;
}

.text {
  text-align: center;
}
