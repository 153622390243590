@use "/src/style/base";

.link {
  &,
  &:visited {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: base.$text-white;
  }

  &:hover,
  &.active {
    color: base.$secondary-color;
  }

  &.mobile {
    color: base.$default-text-color;

    &.active {
      color: base.$text-highlight-color;
      font-weight: bold;
    }
  }
}
