@use "../../../style/base";

.section {
  max-width: 100%;
}

.contactIntroduction {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-around;
}

.contactIntroductionText {
  flex-basis: 45%;
  min-width: 30ch;
  flex-shrink: 1;
  flex-grow: 1;
}

.contactIntroductionImage {
  flex-basis: 45%;
  flex-shrink: 1;
  flex-grow: 1;
  max-width: 95%;
  min-width: 25%;
  margin: min(2rem, 10vw);
  border-radius: base.$border-radius;
}

.contactOptions {
  display: flex;
  flex-wrap: wrap;
}

.contactOptionsWeb,
.contactOptionsOther {
  flex-basis: 30ch;
  flex-grow: 1;
  margin: max(1rem, 1vw);
  border: 1px solid base.$border-default-color;
  border-radius: base.$border-radius;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  text-align: center;
}

.contactOptionsOther {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.icon {
  color: base.$secondary-color;
  font-size: 2em;
}

.calendlyButton {
  margin: calc(0.5rem + 1vh) auto;
}

.otherContactLines {
  display: grid;
  grid-template-columns: 2rem minmax(20ch, 1fr);
  text-align: left;
  gap: 1rem;
  justify-items: start;
  align-items: center;
  margin: 1rem;
}
