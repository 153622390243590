@use "src/style/base";

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: clamp(10px, 1vw, 3rem);
  margin-top: min(3rem, 10vh);
}

.productCategory {
  border: 1px solid base.$border-default-color;
  border-radius: base.$border-radius;
  padding: calc(0.25rem + 0.75vw);
  flex-grow: 1;
  font-size: base.$text-large;
  font-weight: base.$font-lighter;
}

.productSubCategory {
  margin-bottom: 1rem;
  font-size: base.$text-medium;
}

.productLinks {
  padding-left: 0.5em;
}
