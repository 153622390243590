@use "../../../../../style/base";

.actionButtonLink {
  display: block;
  width: 100%;
}

.actionButton {
  width: 100%;
  font-size: base.$text-x-small;
}

.bulletPoints {
  list-style-type: none;
  padding-top: 2em;
  padding-bottom: 3em;
  font-size: base.$text-small;
  padding-left: 0;
}

.bulletPoints > li {
  margin-top: 1em;
  margin-bottom: 1em;
}

.boxContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
