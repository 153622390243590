@use "../../style/base";

.accordion {
  display: flex;
  flex-direction: column;
}

.accordionHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid base.$primary-color;
  transition: 200ms ease-in-out;
  cursor: pointer;
}

.accordionHead:hover {
  opacity: 0.5;
}

.accordionTitle {
  font-weight: normal;
}

.accordionIcon {
  padding: 1rem;
  font-size: base.$text-subtitle;
  color: base.$primary-color;
}

.accordionContent.hidden {
  display: none;
}

.accordionContent.visible {
  display: flex;
}
