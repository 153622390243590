@use "../../../../style/base";

.box {
  background: base.$background-gray;
  border-radius: base.$border-radius;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-basis: 30%;
  flex-shrink: 0;
  min-width: 40ch;
}

.boxTitle {
  text-align: center;
}

.boxIconImage {
  margin-inline: auto;
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.upperWrapper {
  flex: 0 0 8em;
}
