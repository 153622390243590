@use "src/style/base";
@import "src/style/breakpoints";

@include base.media-breakpoint-down(md) {
  .contentContainer {
    width: 100%;
  }
}

.editButton {
  &:visited {
    color: inherit;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
}

.cancelButton,
.submitButton {
  width: 100%;
  margin: 0.3rem;
}

.errorMessage {
  color: var(--error-color);
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
}

.infoDialogButton {
  display: flex;
  justify-content: center;
}
