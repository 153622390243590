@use "src/style/base";

.stepProgress {
  position: relative;
  width: 10rem;

  .progressBar {
    position: absolute;
    height: 5px;
    width: 0;
    top: 50%;
    left: 0;
  }

  .progressNum {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
  }

  .progressNum::before {
    content: "";
    background-color: base.$background-highlight-color;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    height: 1px;
    width: 99%;
    z-index: 0;
  }

  .progressNum > .step {
    border: 1px solid base.$background-purple-gray;
    color: base.$background-purple-gray;
    border-radius: 100%;
    background-color: base.$background-white;
    position: relative;
    display: flex;
    z-index: 1;
    width: 2rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }

  .progressNum .step.active {
    border-width: 2px;
    border-color: base.$border-highlight-color;
    background-color: base.$primary-color;
    color: base.$background-white;
  }
}
