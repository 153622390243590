@use "src/style/base";

.accordion {
  font-size: base.$text-small;
  height: 100%;
  padding: 1.5rem;
}

.accordionMobile {
  width: 100%;
  height: 100%;
  font-size: base.$text-small;
}

.editButton {
  &:visited {
    color: inherit;
  }
}

.title {
  color: base.$text-highlight-color;
  font-weight: lighter;
}

.content {
  border-top: 3px solid base.$text-highlight-color;
}
