@use "../../../../style/base";

.content {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.title {
  text-align: start;
  margin: 0;
}

.highlighted {
  color: base.$secondary-color;
  padding-top: 0;
}

.text {
  flex-direction: column;
  flex-basis: 50%;
  flex-grow: 1;
  padding: 0 2.5em;
  margin-top: 0;
}

.image {
  max-width: 100%;
  max-height: 20em;
  border-radius: base.$border-radius;
  flex: 0 1 20%;
}

@include base.media-breakpoint-down(md) {
  .title {
    text-align: center;
  }
}
