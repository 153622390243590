@use "../../../../../style/base";

$background-opacity-when-hidden-bubble-is-shown: 0.1;
$hidden-bubble-transition-length: 1s;
$normal-bubble-opacity-when-center-bubble-is-hovered: 50%;
$normal-bubble-opacity-transition-length: 0.5s;
$normal-bubble-enlargement-transition-length: 0.5s;

.title,
.subTitle {
  text-align: center;
}

.highlighted {
  color: base.$secondary-color;
}

.canvas {
  position: relative;
}

.canvasImage {
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 100%;
  transition: opacity $hidden-bubble-transition-length;
}

.fadeableBubble {
  transition: transform $normal-bubble-enlargement-transition-length, opacity $normal-bubble-opacity-transition-length;
  opacity: 1;
}

.canvas.movedToBackground .fadeableBubble {
  opacity: $background-opacity-when-hidden-bubble-is-shown;
  pointer-events: none;
}

.canvas.movedToBackground .canvasImage {
  opacity: $background-opacity-when-hidden-bubble-is-shown;
}

.bubble {
  background: base.$primary-color;
  border-radius: 50%;
  aspect-ratio: 1;
  color: base.$text-white;
  display: flex;
  padding: min(0.75em, 20%);
  position: absolute;
  justify-content: center;
  align-items: center;
  font-weight: base.$font-lightest;
  text-align: center;

  /*
    Make bubble 'move' in terms of its center, not top left pixel.
   */
  transform: translate(-50%, -50%);
  font-size: clamp(0.4em, 1.4vw, 1.2em);
  cursor: pointer;
  hyphens: auto;
  text-decoration: none;
}

.masterBubble:hover ~ .fadeableBubble {
  opacity: $normal-bubble-opacity-when-center-bubble-is-hovered;
}

.enlarging:hover {
  transform: translate(-50%, -50%) scale(1.2);
}

.masterBubble {
  left: 47.6%;
  top: 44.3%;
  width: 19.5%;
  hyphens: none;
}

.hideableBubble {
  display: flex;
  flex-direction: column;
  opacity: 1;
  left: 47.6%;
  top: 44.3%;
  z-index: 100;
  width: 30%;
  transition: opacity $hidden-bubble-transition-length;
  border: 0.5em base.$secondary-color solid;
  font-size: base.$text-medium;
  font-weight: base.$font-lightest;
  justify-content: flex-start;
}

.hideableBubble > h1 {
  color: base.$text-white;
  font-weight: base.$font-lightest;
}

.hidden {
  opacity: 0 !important;
  pointer-events: none;
}

@include base.media-breakpoint-down(md) {
  .title,
  .subTitle {
    text-align: left;
  }

  .hideableBubble {
    width: 19.5%;
    opacity: 0;
    font-size: 0;
  }
}
