@use "src/style/base";

.insuranceComparisonBox {
  width: 55rem;
  height: 10.5rem;
  border-radius: 2.4rem;
  border: 1px solid base.$default-border-color;
  background-color: base.$usp-box-bg;
}

.insuranceComparisonHeadlineBox {
  margin-top: 1rem;
  margin-left: 6rem;
  align-items: baseline;
  gap: 0.2rem;
}

.insuranceComparisonHeadline {
  font-weight: base.$font-semibold;
  color: base.$default-text-color;
}

.insuranceComparisonListBox {
  justify-content: space-evenly;
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: auto;
  gap: 2rem;
}

.insuranceComparisonButtonBox {
  height: 3.125rem;
  align-self: flex-end;
}
