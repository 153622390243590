@use "/src/style/base";

.mobileNavigation {
  --mobile-navigation-box-shadow: 0 2px 5px var(--dash-card-shadow-prim);
  --mobile-navigation-padding: 1rem;
  --mobile-navigation-header-height: 72px;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: base.$primary-color;
  box-shadow: var(--mobile-navigation-box-shadow);
  padding: var(--mobile-navigation-padding);

  // Used as a top spacing in the mobile overlay
  min-height: var(--mobile-navigation-header-height);
  z-index: var(--zindex-overlay);
}

.menuOpener {
  position: relative;
  width: 40px;
  z-index: var(--zindex-overlay);
}
