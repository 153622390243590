@use "src/style/base";

.highlighted {
  color: base.$secondary-color;
}

.upperSectionWrapper {
  display: flex;
  max-width: base.$content-width;
  align-items: flex-start;
  margin-inline: auto;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 4em;
}

.upperSection {
  background: base.$background-gray;
  padding: 1em 3em 5em 10vw;
  border-radius: base.$border-radius;
  margin-left: -8vw;
  margin-top: -2em;
  z-index: -1;
  flex-basis: 60%;
  flex-grow: 1;
  min-height: 24em;
}

.upperImage,
.lowerImage {
  border-radius: base.$border-radius;
  max-width: 100%;
  max-height: 20em;
  flex: 0 1 20%;
  margin-inline: auto;
}

.lowerSectionWrapper {
  display: flex;
  margin-top: -15em;
  padding-top: 18em;
  padding-bottom: 3vw;
  z-index: -2;
  position: relative;
  background: base.$primary-color;
  color: base.$text-white;
}

.lowerSection {
  display: flex;
  flex-wrap: wrap;
  max-width: base.$content-width;
  margin-inline: auto;
  justify-content: space-between;
}

.lowerText {
  flex-basis: 60%;
  flex-grow: 1;
  padding: 0 2.5em;
}

.lowerText > h1 {
  margin: 0;
}

@include base.media-breakpoint-down(md) {
  .upperSectionWrapper {
    max-width: 100%;
    margin-top: 0;
  }

  .upperSection {
    background: base.$background-gray;
    border-radius: 0;
    margin: 0;
    padding: 0 base.$page-horizontal-padding;
  }

  .upperImage,
  .lowerImage {
    border-radius: 0;
  }

  .lowerSectionWrapper {
    margin-top: 0;
    padding: 0;
    max-width: 100%;
  }

  .lowerSection {
    max-width: 100%;
  }

  .lowerText {
    padding: 1em base.$page-horizontal-padding 0;
  }
}
