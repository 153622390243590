@use "../../../../../style/base";

.paddinglessHeader {
  padding-top: 0;
  padding-bottom: 0;
}

.overview {
  height: max(calc(100vh - 5rem), 40rem);
  max-height: 60rem;
  margin: 0;

  .content {
    display: flex;
    flex: 1;
    flex-direction: row;
    max-width: base.$content-width;
    margin: 4rem auto;

    .uspBox {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-self: flex-start;
      justify-content: center;
      width: 40%;
      padding: 0;
      margin: 0;

      .uspHeadline {
        font-size: base.$h1;
        font-weight: base.$font-lighter;
        line-height: base.$h1 + 1rem;
        margin-bottom: 2rem;
      }

      .uspBulletpoint {
        height: 2rem;
        margin-top: 0.5rem;
      }

      .uspStatement {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-self: flex-start;
        justify-content: center;
        margin-top: 2rem;
        color: base.$text-white;

        .uspStatementText {
          padding-left: 1rem;
          font-size: base.$text-medium;

          .bold {
            font-weight: base.$font-bold;
          }

          .light {
            font-weight: base.$font-lightest;
          }
        }
      }
    }

    .callToActionBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-flow: row wrap;
      margin-top: 3rem;

      .callToActionButton1 {
        margin-top: 3rem;
        width: 15rem;
      }

      .callToActionButton2 {
        margin-top: 3rem;
        width: 15rem;
        background-color: base.$primary-color;
        border: 2px solid base.$secondary-color;
        color: base.$secondary-color;
      }
    }

    .overviewImageBox {
      width: 55%;
      padding: 0;
      margin: 0;

      .overviewImage {
        width: 100%;
      }
    }
  }
}

@include base.media-breakpoint-down(md) {
  .overviewImageBox {
    display: none;
  }

  .overview {
    .content {
      margin: 1rem auto;

      .uspBox {
        align-self: center;

        .uspHeadline {
          font-size: base.$h1-md;
        }

        .uspBulletpoint {
          margin-top: 0;
        }

        .uspStatementText {
          .light {
            display: none;
          }
        }

        .callToActionBox {
          justify-content: center;
          margin-top: 0;
        }
      }
    }
  }
}
