@use "../../style/base";

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: base.$page-width;
  width: 100%;
  margin: auto;
  border: 1px solid base.$background-gray-darker;
}

.content {
  width: 100%;
  margin: auto;
  min-height: calc(100vh - 26rem - 5rem);
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: "Open Sans", sans-serif;
}

.contentMobile {
  max-width: 100vw;
}

.darkPage {
  background: base.$primary-color;
  color: base.$text-white;
}
