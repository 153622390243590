// default colors

$primary-color: #0e123e;
$secondary-color: #ff7172;
$background-default-color: #fff;
$background-highlight-color: $secondary-color;
$background-gray: #f0f1f5;
$background-gray-darker: #dbdbde;
$background-white: #fff;
$background-blue: #c3e4f4;
$background-purple-gray: #86889e;
$text-default-color: $primary-color;
$text-highlight-color: $secondary-color;
$text-purple-gray: #86889e;
$headline-color: #203151;
$accent-color: #ffa43b;
$accent-color-lighter: #fff9f2;
$border-default-color: $primary-color;
$border-highlight-color: $secondary-color;
$border-white: #fff;
$border-gray: #a6a6a6;
$portrait-border-color: #a6a6a6;
$input-border-color: #767676;
$input-border-color-active: #212121;

// specific mobile colors
$navbar-mobile-color: #fff;
$highlight-background: #f1fbff;
$highlight-border: #86bcd6;
$highlight-shadow: #a9c0f1;
$gradient-color-lighter: #eefffb;
$gradient-color-darker: #c3e4f4;
$default-text-color: #585d63;
$default-text-color-darker: #252a41;
$lighter-text-color: #585858;
$text-headline-color: #515a80;
$btn-primary: #ff7172;
$btn-primary-hover: #252a41;
$btn-secondary: #252a41;
$btn-secondary-hover: #3e4151;
$text-white: #fff;
$font-black: #000;
$font-red: #f00;
$font-green: #008000;
$linktext-default: #4472c4;
$icon-turquoise: #38a6a6;
$icon-red: #92013e;
$usp-box-bg: #efefef80;
$border-light-gray: #d2d2d2;
$switch-toggle-inactive: #bfbfbf;
$switch-toggle-inactive-bg: #fff;
$switch-toggle-active: #fff;
$switch-toggle-active-bg: #515a80;
$switch-toggle-border: #bfbfbf;
$filter-box-active: #b9cac9;
$filter-sort-dropdown: #cfe3e1;
$filter-sort-button: #fff;
$filter-sort-item-rule: #c4bebe;
$tooltip-text-default: #c4bebe;
$tooltip-background-default: #b1c4c2;
$default-border-color: #585d63;
