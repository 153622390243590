@use "src/style/base";
@import "src/style/breakpoints";

.label {
  display: flex;
  font-weight: bold;
  padding-right: 4px;
  align-items: flex-start;
  color: base.$text-default-color;
}

.valueContainer {
  overflow: hidden;
  display: flex;
  align-items: flex-end;
}

.value {
  text-align: right;
  overflow: hidden;
  max-width: 100%;
  color: base.$text-default-color;
}

.userProfileItem {
  display: flex;
  justify-content: space-between;
  padding: 4px 6px;

  .profileInput {
    input {
      height: 32px;
      padding: 0 1rem;
      width: 100%;
    }
  }
}
