@use "/src/style/base";

.hamburgerIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: inherit;
  cursor: pointer;

  &::before,
  &::after,
  .burgerLayer {
    background-color: base.$text-white;
    content: "";
    display: block;
    height: 4px;
    border-radius: 3px;
    margin: 6px 0;
    transition: var(--mobile-navigation-transition-delay);
  }

  &:hover {
    &::before,
    &::after,
    .burgerLayer {
      background-color: base.$text-white;
    }
  }

  &.active {
    &::before,
    &::after,
    .burgerLayer {
      margin: 8px 0;
    }

    &::before {
      transform: translateY(12px) rotate(135deg);
    }

    &::after {
      transform: translateY(-12px) rotate(-135deg);
    }

    .burgerLayer {
      transform: scale(0);
    }
  }
}
