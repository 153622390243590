@use "src/style/base";

.contractBaseSection {
  display: flex;
  gap: 3rem;
  flex: 1;
}

.contractBaseSection > * {
  flex: 1;
}

.contractData {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.uploadContract {
  border: 1px solid base.$border-highlight-color;
  border-radius: base.$border-radius;
  padding: 1rem;
  font-size: base.$text-small;
  color: base.$text-default-color;
}

.uploadContractLater {
  font-size: base.$text-x-small;
  color: base.$text-default-color;
  padding: 0.5rem 1rem;
}

.bold {
  font-weight: bold;
}

@include base.media-breakpoint-down(md) {
  .contractBaseSection {
    flex-direction: column;
  }
}
