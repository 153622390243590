@use "src/style/base";

.subText {
  padding-left: 0.5rem;
  padding-top: 0.1rem;
  font-size: base.$text-x-small;
}

.help {
  color: base.$default-text-color;
}

.error {
  color: base.$font-red;
}

.label {
  font-size: base.$text-small;
  margin-bottom: 0.25rem;
  padding-left: 0.5rem;
  color: base.$default-text-color-darker;
}

.labelLight {
  color: base.$text-white;
}

@mixin input-base {
  border-width: 1px;
  border-radius: base.$border-radius;
  border-style: inset;
  border-color: base.$input-border-color;
  height: 2.25rem;
  min-height: 2.25rem;
  font-size: base.$text-small;
  font-weight: base.$font-light;
}

@mixin input-padding {
  padding: 0.5rem;
}

@mixin input-color {
  color: base.$default-text-color;
}

.input {
  @include input-base;
  @include input-padding;
  @include input-color;

  width: 100%;
}

.textarea {
  height: auto;
}

.selectInput {
  [class$="-control"] {
    @include input-base;
  }

  [class$="-control"]:hover {
    border-style: inset;
    border-color: base.$input-border-color;
  }

  [class$="-control"]:active,
  [class$="-control"]:focus-within {
    border-color: base.$input-border-color-active;
    box-shadow: inset 0 0 0 1px base.$input-border-color-active;
  }

  [class$="-Input"],
  [class$="-singleValue"] {
    @include input-color;
  }
}

.phoneInput {
  input {
    @include input-base;
    @include input-padding;
    @include input-color;
  }
}
