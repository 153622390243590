@use "/src/style/base";

.mobileNavigationOverlay {
  position: absolute;

  // Must match mobile header height
  top: var(--mobile-navigation-header-height);
  left: 0;
  right: 0;
  height: calc(100vh - var(--mobile-navigation-header-height));
  max-width: 300px;
  background-color: base.$navbar-mobile-color;
  box-shadow: var(--mobile-navigation-box-shadow);
  border-bottom-right-radius: 4px;
  opacity: 0;
  transform-origin: 0 0;
  transform: translate(-100%, 0);
  transition: all var(--mobile-navigation-transition-delay);
  z-index: var(--zindex-overlay);
  padding-top: 15px;
  overflow-y: scroll;

  &.visible {
    opacity: 1;
    transform: none;
  }
}

.navigationItems {
  display: flex;
  flex-direction: column;
}

.navigationItem {
  padding: 15px var(--default-space-horizontal-mobile);
  font-size: base.$text-medium;
  color: base.$default-text-color;
}

.navigationItemSeparator {
  padding: 10px var(--default-space-horizontal-mobile);

  .line {
    border-bottom: 2px solid base.$border-gray;
  }
}

.navigationIcon {
  font-size: base.$text-small;
  padding-right: 1rem;

  svg {
    height: 2rem;
    width: 2rem;
  }
}
