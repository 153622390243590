@use "src/style/base";

.progressBar {
  position: relative;
  border-radius: base.$border-radius;
  background: base.$background-gray-darker;
  width: 100%;
  height: 25px;
  box-shadow: none;
  text-align: center;
}

.progressBarFull {
  position: absolute;
  border-radius: base.$border-radius;
  width: var(--width);
  color: white;
  height: 100%;
}

.progressText {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: right;
  padding-right: 8px;
  padding-left: 8px;
  color: base.$text-white;
  mix-blend-mode: difference;
}

@include base.media-breakpoint-down(md) {
  .dashboardStatsContainer {
    width: 100%;
    padding: 0;
  }
}
