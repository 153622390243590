@use "src/style/base";

.smallLogoutButton {
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    color: base.$primary-color;
  }
}

.icon {
  font-size: 1.3em;
}

.desktopNavigationLogoutButton {
  margin: 0;
}
