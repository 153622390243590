@use "src/style/base";
@import "src/style/breakpoints";

.userProfileItem {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  gap: 0.5rem;

  .labelContainer {
    display: flex;
    width: 12rem;
    align-items: center;
  }

  .label {
    font-weight: bold;
    font-size: base.$text-small;
    width: 100%;
  }

  .inputContainer {
    width: 15rem;
  }
}

@include base.media-breakpoint-down(md) {
  .userProfileItem {
    flex-direction: column;
  }

  .label {
    font-size: base.$text-small;
  }
}
