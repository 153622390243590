@use "src/style/base";

.heading {
  font-size: base.$text-x-large;
  font-weight: base.$font-light;
  color: base.$default-text-color;
  padding: 0;
  margin: 0;
}

.headingRed {
  font-weight: base.$font-semibold;
  color: base.$text-highlight-color;
}

.navigationContainer {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
}

.navigationCard {
  @include base.rounded-box;

  background: base.$background-white;
  text-align: center;
  cursor: pointer;
  padding: 1.5rem;
}

.icon {
  width: 4rem;
  height: 4rem;
}
