@use "../../style/base";

.introductionText {
  margin: 1rem;
  text-align: left;
  color: base.$default-text-color;
}

.registerBox {
  padding: 1rem;
  border: 1px solid base.$border-light-gray;
  border-radius: base.$border-radius;
  background-color: base.$background-gray;
  width: min(90vw, 25rem);
}

.inputsBox {
  gap: 0.5rem;
}

.title {
  margin-bottom: 0.75rem;
  font-size: base.$text-x-large;
  color: base.$default-text-color;
  font-weight: base.$font-semibold;
}

.submitButton {
  width: 100%;
  margin-top: 1.5rem;
}

.formErrorContainer {
  max-width: min(90vw, 25rem);
}

.formError {
  color: base.$font-red;
}

@include base.media-breakpoint-down(md) {
  .submitButton {
    max-width: inherit;
  }
}
