// Fonts
@import "@fontsource/open-sans/index.css";

// Original Fontsizes
$h1-big: 5.6rem;
$h1: 3.125rem;
$h2-big: 4.7rem;
$h2: 2rem;
$span: 1.3rem;
$p: 1rem;
$btn-label-regular: 1.1rem;
$text-x-x-small: 0.56rem;
$text-x-small: 0.75rem;
$text-small: 0.9rem;
$text-medium: 1rem;
$text-large: 1.3rem;
$text-x-large: 2rem;
$navigation-item: 1.1rem;
$text-subtitle: 1.4rem;
$text-title: 2rem;

// Fontsizes for Breakpoint XL
$h1-big-xl: 50px;
$h1-xl: 2.5rem;
$h2-big-xl: 2.5rem;
$h2-xl: 22px;
$span-xl: 16px;
$p-xl: 14px;
$btn-label-regular-xl: 1.3rem;

// Fontsizes for Breakpoint MD
$h1-big-md: 2.5rem;
$h1-md: 2.5rem;
$h2-big-md: 30px;
$h2-md: 22px;
$span-md: 16px;
$p-md: 14px;
$btn-label-regular-md: 1rem;

// Fontweight
$font-bold: 700;
$font-semibold: 700;
$font-normal: 400;
$font-light: 300;
$font-lighter: 200;
$font-lightest: 100;
