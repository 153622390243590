@use "src/style/base";

.featureIcon {
  margin-right: 0.3rem;
  color: base.$default-text-color;
}

.featureText {
  color: base.$default-text-color;
}
