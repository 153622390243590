@use "src/style/base";

.mailbox {
  .mailboxHeader {
    display: flex;
    justify-content: space-between;
    justify-items: flex-end;
    align-items: flex-end;
    border-bottom: 1px solid base.$primary-color;
    padding-bottom: 0.5rem;

    .switch {
      display: flex;
      gap: 1rem;
    }

    h3 {
      color: base.$text-purple-gray;
      margin: 0;
      cursor: pointer;
    }

    h3.selected {
      color: base.$text-default-color;
    }
  }

  .mailboxContent {
    display: flex;
    min-height: 100%;
    padding-top: 1rem;
    gap: 1rem;

    .noMessages {
      font-size: base.$text-small;
      color: base.$default-text-color;
    }

    .messageList {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      flex-grow: 1;

      .accordionTitle {
        display: flex;
        width: 100%;
      }

      .messageUnread {
        font-weight: base.$font-bold;
      }

      .messageHeader {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        padding-right: 1rem;

        .icon {
          height: 100%;
          display: flex;
          align-items: center;

          svg {
            width: 2rem;
            height: 2rem;
          }
        }

        .sendDateContainer {
          display: flex;
          justify-content: space-between;
        }

        .sentDate {
          flex-shrink: 0;
          padding-left: 0.5rem;
        }

        .sentDateText {
          font-size: base.$text-x-small;
          color: base.$text-default-color;
        }

        .subject {
          display: flex;
          gap: 1rem;
          align-items: center;
        }

        .subjectText {
          font-size: base.$text-small;
        }
      }
    }

    .messageDetails {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      background: base.$background-gray;
      border-radius: base.$border-radius;

      .body {
        padding: 1rem;
        flex-grow: 1;
      }

      .bodyText {
        color: base.$text-default-color;
        font-size: base.$text-small;
      }
    }
  }
}

@include base.media-breakpoint-down(md) {
  .mailbox {
    .mailboxHeader {
      .switch {
        flex-direction: column;
        gap: 0.5rem;
      }

      button {
        width: 50%;
      }
    }

    .mailboxContent {
      .messageList {
        .messageHeader {
          .icon {
            svg {
              width: 1rem;
              height: 1rem;
            }
          }
        }
      }
    }
  }
}
