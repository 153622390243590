@use "../../style/base";

$input-width: 30px !default;
$input-height: 40px !default;

.authenticationCodeInput {
  display: flex;
  gap: 10px;
}

.digitContainer {
  width: $input-width;
  height: $input-height;
  padding-bottom: 5px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom: 3px solid #333;

  &.focused {
    animation: blink 1s infinite 0s;
    background-color: base.$background-gray-darker;
  }
}

// Element selector is required to get a higher precedence
input[type="text"].digitInput {
  position: relative;
  width: 100%;
  height: 100%;
  border: 0 none;
  padding: 0;
  margin: 0;
  text-align: center;
  color: var(--input-color);
  font-size: $input-height;
  background-color: transparent;

  // Hides the blinking cursor
  caret-color: transparent;

  // No need for a selection, we have our own focus marker
  &::selection {
    background-color: transparent;
  }
}

@keyframes blink {
  from {
    border-bottom-color: base.$primary-color;
  }

  to {
    border-bottom-color: #000;
  }
}

@include base.media-breakpoint-down(md) {
  $input-height: 30px;

  .authenticationCodeInput {
    gap: 5px;
  }
}
