@use "src/style/base";

$highlight-time: 0.35s;
$icon-toggle-time: 0.3s;
$box-toggle-time: 0.5s;

.title {
  font-size: base.$text-large;
}

.subtitle {
  font-size: base.$text-small;
  font-weight: normal;
  padding: 0.3rem 0;
}

.actionIcon {
  font-size: base.$text-large;
  color: base.$text-default-color;
  font-weight: 100;
}

.header {
  display: flex;
  color: base.$text-default-color;
  cursor: pointer;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 1;
}

.icon {
  display: flex;
  align-items: center;
  width: 1rem;
  height: 1rem;
  margin: 0.5rem;
}

.toggleIcon {
  flex: 0;
  justify-self: flex-end;
  transition: all #{$icon-toggle-time};
}

.accordionBox {
  @include base.rounded-box;

  display: flex;
  flex-direction: column;
  padding: 1rem;
  transition: all #{$highlight-time} ease;
  background-color: base.$background-white;

  .contentContainer {
    flex: 1;
    overflow: hidden;
    transition: max-height #{$box-toggle-time} ease-in-out;
    transition-delay: 0.1s;

    .content {
      margin-top: 0.5rem;
      padding-top: 1rem;
      padding-bottom: 0;
    }
  }

  &[aria-expanded="false"] {
    .header {
      .toggleIcon {
        transform: rotateZ(90deg);
      }
    }

    .contentContainer {
      max-height: 0;
      transition: max-height #{$box-toggle-time} ease;
      transition-delay: 0s;
    }
  }

  @keyframes box-highlight {
    0% {
      box-shadow: 0 0 6px 0 #f00;
      opacity: 0;
    }

    10% {
      box-shadow: none;
      opacity: 0.2;
    }

    20% {
      box-shadow: 0 0 6px 0 #f00;
      opacity: 0.5;
    }

    30% {
      box-shadow: none;
      opacity: 0.7;
    }

    40% {
      box-shadow: 0 0 6px 0 #f00;
      opacity: 1;
    }

    50% {
      box-shadow: none;
    }

    60% {
      box-shadow: 0 0 6px 0 #f00;
    }

    70% {
      box-shadow: none;
    }

    80% {
      box-shadow: 0 0 6px 0 #f00;
    }

    100% {
      box-shadow: none;
    }
  }
}
