@use "../../../style/base";

.dropField {
  display: flex;
  width: 100%;
  padding: 0.5rem;
  flex-direction: column;

  .dropIt {
    display: flex;
    background: #fff;
    width: inherit;
    height: 100%;
  }

  h4 {
    font-size: base.$text-small;
    color: base.$text-default-color;
    font-weight: normal;
    text-align: center;
  }
}

.zone {
  display: flex;
  width: inherit;
  height: inherit;

  .onDrag {
    display: flex;
    width: inherit;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid base.$border-default-color;
    border-radius: 1rem;
    padding: 0.5rem;
    transition-duration: 200ms;
    transition-property: box-shadow, background-color;

    .icon {
      width: 3rem;
      height: auto;
    }
  }

  .noDragOuter {
    display: flex;
    width: inherit;
    height: inherit;

    .noDrag {
      display: flex;
      width: inherit;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: base.$border-radius;
      border: 2px solid rgb(0 0 0 / 0%);
      padding: 0.5rem;
      box-shadow:
        inset -3px -3px 2rem rgb(var(--secondary-color-rgb) 0.3),
        inset 3px 3px 2rem rgb(var(--secondary-color-rgb) 0.3);
      background-color: rgb(var(--secondary-color-rgb) 0.1);
      transition-duration: 200ms;
      transition-property: box-shadow, background-color;

      .icon {
        width: 3rem;
        height: auto;
      }

      span,
      p {
        margin: 0;
        color: base.$text-default-color;
      }

      span {
        color: base.$text-default-color;
      }

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0.5rem;

        li {
          color: base.$text-default-color;
        }
      }
    }
  }
}
