@use "src/style/base";

.insuranceOptionCardBox {
  border-radius: 20px;
  border: 1px solid base.$default-border-color;
  width: 16rem;
  height: 5rem;
}

.insuranceOptionPriceText {
  align-self: flex-end;
  margin-right: 1rem;
  margin-top: 0.3rem;
  color: base.$default-text-color;
}

.insuranceOptionCardContent {
  margin-top: 0.2rem;
}

.insuranceOptionCardCheckbox {
  width: 2rem;
  height: 2rem;
  margin: 0.5rem 1rem 1.2rem 0.5rem;

  &:checked::before {
    width: 0.6rem;
    height: 1.4rem;
    margin-left: 0.6rem;
    margin-top: 0.2rem;
  }
}

.insuranceOptionCardIcon {
  width: 24px;
  height: 24px;
  color: base.$default-text-color;
  margin-left: 0.5rem;
}

.insuranceOptionCardDescriptionText {
  width: 8rem;
}

.toolTipBox {
  align-self: flex-end;
  margin-top: -1.4rem;
  margin-right: 0.3rem;
}
