@use "src/style/base";

.productGrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 1.5rem;
}

@include base.media-breakpoint-down(md) {
  .productGrid {
    grid-template-columns: 1fr;
  }
}
