@use "src/style/base";

.filterButton {
  padding: 0.3rem 0.5rem;
  border: 1px solid base.$default-border-color;
  border-radius: 20px;
  background-color: base.$filter-sort-button;
  z-index: 1;
  align-items: center;
  color: base.$default-border-color;
  cursor: pointer;
  user-select: none;
}

.textLeft {
  justify-content: flex-start;

  .arrowIcon {
    margin-left: auto;
  }
}

.textRight {
  justify-content: flex-end;
  gap: 0.5rem;
}

.inputLabel {
  color: base.$default-text-color;
}

.filterCriteriaBox {
  display: none;
  border: 1px solid base.$default-border-color;
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: -0.8rem;
  padding: 1.2rem 1rem 0.5rem;
  text-align: right;
  background-color: base.$filter-sort-dropdown;
  color: base.$default-text-color;
}

.contentLeft {
  text-align: left;
}

.contentRight {
  text-align: right;
}

.filterCriteriaBoxOpen {
  display: flex;
}

.filterCriteriaItem {
  cursor: pointer;
  line-height: 1px;

  &:not(:first-child) {
    border-top: 1px solid base.$filter-sort-item-rule;
  }
}
