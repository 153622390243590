@use "../../../../../style/base";

.section {
  text-align: center;
}

.title {
  margin-bottom: 2em;
}

.highlighted {
  color: base.$secondary-color;
}

.upperTiles,
.lowerTiles {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.tile {
  border-radius: base.$border-radius;
  padding: 1.5em max(0.5em, 2vw);
  text-align: center;
  flex-grow: 0;
  flex-shrink: 1;
}

.tile > h3 {
  margin-top: 0;
}

.tile.large {
  flex: 1;
  min-width: 40ch;
}

.tile.small {
  flex: 1;
  min-width: 30ch;
}

.tile.lightBackground {
  background: base.$background-gray;
}

.tile.darkBackground {
  background: base.$background-gray-darker;
}

@include base.media-breakpoint-down(md) {
  .section {
    width: 100%;
    margin: 0;
    text-align: left;
  }

  .section > h1 {
    margin-bottom: 1em;
  }

  .tile {
    border-radius: 0;
    padding: 0.5em max(0.5em, 2vw);
    text-align: left;
    font-size: base.$text-small;
  }

  .tile > h3 {
    margin-bottom: 0.5em;
    font-size: base.$text-medium;
    font-weight: base.$font-lightest;
  }

  .tile.large,
  .tile.small {
    flex: 1;
    min-width: 30ch;
  }
}
