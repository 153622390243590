@use "../../../../../style/base";

.section {
  display: flex;
  flex: 1;
  flex-flow: row wrap;
  align-items: center;
}

.sectionContent {
  display: flex;
  justify-content: center;
}

.teaserImage > img {
  max-width: 100%;
  max-height: 100%;
  height: 35em;
}

.teaserImage {
  display: flex;
  flex-basis: 40%;
  align-items: center;
  justify-content: center;
}

.highlightedTitle {
  color: base.$secondary-color;
}

.teaserText {
  flex-basis: 50%;
  margin-right: 1rem;
}

.portalButton {
  display: block;
  min-width: base.$button-desired-width;
  margin-top: 2em;
}

@include base.media-breakpoint-down(md) {
  .teaserImage {
    display: none;
  }

  .section {
    flex-flow: column wrap;
    margin: 0;
  }

  .sectionContent {
    flex-flow: column wrap;
    flex-basis: 100%;
    margin: 0;
    padding: 0;
    text-align: left;
  }

  .teaserText {
    padding: 0;
    margin: 0;
  }

  .portalButton {
    margin: 2em auto;
  }

  .buttonLink {
    margin-inline: auto;
  }
}
