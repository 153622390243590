@use "src/style/base";

.formGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.accordionContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.controlContainer {
  display: flex;
  justify-content: center;

  button {
    width: 100%;
  }
}

@include base.media-breakpoint-down(md) {
  .formGrid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.25rem;
  }

  .label {
    padding-top: 0.25rem;
  }
}
