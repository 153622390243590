@use "src/style/base";

.cardView {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;

  ~ * {
    margin-top: 2rem;
  }
}

.listView {
  width: 100%;
  font-size: base.$text-small;
  color: base.$default-text-color;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
