.logo {
  max-width: 100%;
  max-height: 100%;
}

.small {
  width: 140px;
}

.large {
  width: 200px;
}
