@use "src/style/base";

.badgeBox {
  border: 2px solid lightgray;
  border-radius: 4px;
  margin-top: 3rem;
  padding: 1.5rem;
  box-shadow: 2px 2px 2px rgb(173 182 217 / 50%);
  align-items: center;
  min-width: 30rem;
  justify-content: space-between;
  color: base.$default-text-color;
}

.priceBox {
  justify-items: flex-end;
}

.price {
  color: blue;
  font-weight: 600;
  justify-content: flex-end;
}

.period {
  justify-content: flex-end;
  margin-top: -0.5rem;
  color: base.$default-text-color;
}
