@use "src/style/base";

.contentContainer {
  max-width: 65ch;
}

.buttonContainer {
  justify-content: center;
}

.button {
  min-width: 10rem;
}
